import { createTheme, adaptV4Theme } from '@mui/material/styles';

const theme = createTheme(adaptV4Theme({
    palette: {
        primary: {
            main: '#9BA5AE'
        },
        secondary: {
            main: '#008BE1'
        }
    }
}));

export default theme;
