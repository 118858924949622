import React from 'react'
import { AuthenticationProvider, oidcLog, OidcSecure } from '@axa-fr/react-oidc-context';
import { useConfig } from './ConfigLoader';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { FrontendAccessLevel } from '../utils/Utils';
import CenteredProgress from './CenteredProgress';
import { AuthorizationContext } from '../utils/AuthorizationContext';
import AccessDenied from './AccessDenied';
import { Stack } from "@mui/material";
import AppHeader from "./AppHeader";

const serverUrl = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

function AdminPanelAuthorization(props: any) {
    const { oidcUser } = useReactOidc();
    const [access, setAccess] = React.useState<FrontendAccessLevel>(
        FrontendAccessLevel.NoAccess
    );
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (!oidcUser) {
            setIsLoading(false)
            return;
        }

        const authorizationUrl =
            window.location.origin + "/api/v2/frontendhelper/getauthorization";
        const options: RequestInit = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${oidcUser.access_token}`,
            },
        };

        fetch(authorizationUrl, options)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
                return undefined;
            })
            .then((data) => {
                if (data in FrontendAccessLevel)
                    setAccess(data as FrontendAccessLevel);
                else
                    throw new Error("Invalid access: " + data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return isLoading ? (
        <CenteredProgress />
    ) : access === FrontendAccessLevel.NoAccess ? (
        <Stack>
            <AppHeader />
            <AccessDenied />
        </Stack>
    ) : (
        <AuthorizationContext.Provider value={access}>
            <React.Fragment>{props.children}</React.Fragment>
        </AuthorizationContext.Provider>
    );
}

export function Authenticate(props: any) {
    const config = useConfig();

    const oidcConfiguration = {
        client_id: 'contextcapture-webapp',
        redirect_uri: serverUrl + '/signin-oidc',
        response_type: 'code',
        scope: 'openid context-capture-service-2594 email profile organization',
        authority: config.ims.authorityUri,
        silent_redirect_uri: serverUrl + '/silent_renew',
        automaticSilentRenew: true,
        loadUserInfo: true,
        triggerAuthFlow: true,
        silentRequestTimeout: 20000,
        accessTokenExpiringNotificationTime: 1800
    };

    return (
        // DOC:
        // https://github.com/AxaGuilDEv/react-oidc/tree/master/packages/context#readme
        <AuthenticationProvider
            configuration={oidcConfiguration}
            loggerLevel={oidcLog.WARN}
            authenticating={React.Fragment}
            callbackComponentOverride={React.Fragment}>
            <OidcSecure>
                <AdminPanelAuthorization>
                    {props.children}
                </AdminPanelAuthorization>
            </OidcSecure>
        </AuthenticationProvider>
    )
}
