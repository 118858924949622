import { Tooltip, Typography } from "@mui/material";

export enum FrontendAccessLevel {
    NoAccess = "NoAccess",
    Admin = "Admin",
    ViewOnly = "ViewOnly",
    Client = "Client"
}
export function toArray<Type>(maybeJobs: Type | Type[]): Type[] {
    return Array.isArray(maybeJobs) ? maybeJobs : [maybeJobs];
}


export function downloadUri(uri: string) {
    const link = document.createElement('a');
    link.href = uri;
    link.target = "_blank";
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function downloadUris(uris: string[]) {
   uris.forEach(uri => {
        const link = document.createElement('a');
        link.href = uri;
        link.target = "_blank";
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
}

export function arrayUnique(array: any[]) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
}

export function exportToJson(jsonString: string, fileName: string) {
    if (jsonString === undefined) return;
    if (fileName === undefined) fileName = "data.json";
    const link = document.createElement("a");
    link.href = `data:text/json;chatset=utf-8,${encodeURIComponent(
        jsonString
      )}`;
    link.download = fileName;
    link.click();
};

export function hasAdminOrViewOnlyAccess(frontendAccessLevel:FrontendAccessLevel) {
    return frontendAccessLevel === FrontendAccessLevel.Admin || frontendAccessLevel === FrontendAccessLevel.ViewOnly;
}


export function EmailEllipsis(props: { email: string }) {
    return (
        <Tooltip title={props.email}>
            <Typography
                variant="inherit"
                style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxHeight: "45px",
                    overflowWrap: "break-word",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    display: "-webkit-box",
                }}
            >
                {props.email}
            </Typography>
        </Tooltip>
    );
}

export function CenteredText(props: { text: string }) {
    return (
        <div
            style={{
                display: "flex",
                padding: "2rem",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <div>{props.text}</div>
        </div>
    );
}
