import { Typography } from "@mui/material";

export default function AccessDenied() {
    return (
        <div style={{"display":"flex","height":"85vh","alignItems":"center","justifyContent":"center"}}>
        <Typography variant="h3">
           Access denied
        </Typography>
        </div>
    );
}