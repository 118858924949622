import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ProfileAvatar from './ProfileAvatar';
import { Link } from 'react-router-dom';
import { IconButton, Avatar, Tooltip } from '@mui/material';
import { useConfig } from './ConfigLoader';
import { styled } from '@mui/system';
import { useAuthorizationContext } from '../utils/AuthorizationContext';
import { FrontendAccessLevel, hasAdminOrViewOnlyAccess } from '../utils/Utils';
import { ApplicationName } from "../utils/Constants";

const StyledTypohraphy = styled(Typography)((props) => ({
    flexGrow: 1,
    marginLeft: props.theme.spacing(1),
}));

const StyledLink = styled(Link)(() => ({
    textDecoration: "none",
    color: "inherit",
}));

interface ILinkIconParams {
    name: string;
    href: string;
}

function LinkIcon(props: ILinkIconParams) {
    var url = new URL(props.href);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        window.open(props.href, "_blank");
    };

    return (
        <Tooltip title={props.name}>
            <IconButton
                color="inherit"
                aria-label={props.name}
                onClick={handleClick}
                size="large"
            >
                <Avatar alt={props.name} src={url.origin + "/favicon.ico"} />
            </IconButton>
        </Tooltip>
    );
}

export default function AppHeader() {
    const config = useConfig();
    const frontendAccessLevel = useAuthorizationContext();
    const isAdminOrViewOnlyUser = hasAdminOrViewOnlyAccess(frontendAccessLevel);

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" color="inherit" noWrap>
                    {frontendAccessLevel === FrontendAccessLevel.NoAccess ? (
                        <>{ApplicationName}</>
                    ) : (
                        <StyledLink to="/admin/dashboard">
                            {ApplicationName}
                        </StyledLink>
                    )}
                </Typography>
                <StyledTypohraphy
                    variant="caption"
                    color="inherit"
                    gutterBottom={true}
                    noWrap
                >
                    {config.version}
                </StyledTypohraphy>

                {isAdminOrViewOnlyUser && (
                    <>
                        <LinkIcon name="Seq" href={config.seqUri} />
                        <LinkIcon
                            name="Azure Portal"
                            href={
                                "https://portal.azure.com/#" +
                                config.azurePortalResourceGroup
                            }
                        />
                    </>
                )}

                <ProfileAvatar />
            </Toolbar>
        </AppBar>
    );
}
