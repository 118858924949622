import * as React from 'react';
import MaterialTable from "@material-table/core";
import { toArray } from '../utils/Utils';
import { useFetchApi } from '../utils/UseFetchApi'
import { AddBox } from '@mui/icons-material';
import { Typography, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

interface IEnterpriseLimit
{
    ultimateSiteId: string;
    maxEngines: number;
    comment: string;
}

interface ILimits {
    id: string;
    limits: IEnterpriseLimit[];
}

interface IUniqueLimits {
    id: string;
    limit: IEnterpriseLimit;
}

export default function Limits() {
    const limitsApi = useFetchApi<ILimits[]>(window.location.origin + "/api/v2/limits/all");
    const fetchApi = useFetchApi<any>()

    function FormDialog() {
        const [open, setOpen] = React.useState(false);

        const validationSchema = yup.object({
            userId: yup.string()
                .required('User Id is required'),
            email: yup.string()
                .email('Enter a valid email')
                .required('Email is required'),
            siteId: yup.string()
                .required('Ultimate Site Id is required'),
            siteName: yup.string()
                .required('Enterprise name is required'),
            engines: yup.number()
                .min(10, "Max engines must be greater than default")
                .max(500, "Max engines must be lower than 500")
          });

        const formikWrap = useFormik({
            validateOnChange: true,
            validateOnBlur: true,
            initialValues: {
              userId: "",
              email: "",
              siteId: "",
              siteName: "",
              engines: 10,
              comment: ""
            },
            validationSchema: validationSchema,
            onSubmit: (values) => {
              alert(JSON.stringify(values, null, 2));
            },
        });

        const handleClickOpen = () => {            
            formikWrap.resetForm()
            setOpen(true);
        };
      
        const handleClose = () => {
            setOpen(false);
        };
        
        const { enqueueSnackbar } = useSnackbar();

        const handleAdd = () => {
            formikWrap.validateForm();
            if (!formikWrap.isValid)
                return;
                
            setOpen(false)
            // formikWrap.handleSubmit(); for debug
            enqueueSnackbar("Adding new limit for " + formikWrap.values.email, { variant: "info" });
            const fullComments = new Array(formikWrap.values.email, formikWrap.values.siteName, formikWrap.values.comment);
            const limit: IEnterpriseLimit = { maxEngines: formikWrap.values.engines, ultimateSiteId: formikWrap.values.siteId, comment: fullComments.join(' - ') };

            const limits = fetchApi.run(window.location.origin + "/api/v2/limits/" + formikWrap.values.userId,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(limit)
                });

            if (!limits)
                enqueueSnackbar("Failed to add limits, check SEQ logs", { variant: "error" });
            else
                enqueueSnackbar("Limit added for " + formikWrap.values.email, { variant: "success" });
            limitsApi.run().then(() => { limitsApi.run(); });
        }
      
        return (            
            <span>
                <Button startIcon={<AddBox />} variant="contained" color="secondary" size="small" disableElevation onClick={handleClickOpen}>Add a new limit</Button>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add limit</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Add or replace a limit for a user/enterprise couple
                        </DialogContentText>
                        <TextField
                            autoFocus margin="dense" id="userId" label="User Id" type="text"
                            value={formikWrap.values.userId}
                            onChange={formikWrap.handleChange}
                            onBlur={formikWrap.handleBlur}
                            error={formikWrap.touched.userId && Boolean(formikWrap.errors.userId)}
                            helperText={formikWrap.touched.userId && formikWrap.errors.userId}
                            fullWidth required color="secondary"
                            variant="standard"
                        />
                        <TextField
                            margin="dense" id="email" label="User email" type="email"
                            value={formikWrap.values.email}
                            onChange={formikWrap.handleChange}
                            onBlur={formikWrap.handleBlur}
                            error={formikWrap.touched.email && Boolean(formikWrap.errors.email)}
                            helperText={formikWrap.touched.email && formikWrap.errors.email}
                            fullWidth required color="secondary"
                            variant="standard"
                        />
                        <TextField
                            margin="dense" id="siteId" label="Ultimate Site Id" type="text"
                            value={formikWrap.values.siteId}
                            onChange={formikWrap.handleChange}
                            onBlur={formikWrap.handleBlur}
                            error={formikWrap.touched.siteId && Boolean(formikWrap.errors.siteId)}
                            helperText={formikWrap.touched.siteId && formikWrap.errors.siteId}
                            fullWidth required color="secondary"
                            variant="standard"
                        />
                        <TextField
                            margin="dense" id="siteName" label="Enterprise Name" type="text"
                            value={formikWrap.values.siteName}
                            onChange={formikWrap.handleChange}
                            onBlur={formikWrap.handleBlur}
                            error={formikWrap.touched.siteName && Boolean(formikWrap.errors.siteName)}
                            helperText={formikWrap.touched.siteName && formikWrap.errors.siteName}
                            fullWidth color="secondary"
                            variant="standard"
                        />
                        <TextField
                            margin="dense" id="engines" label="Max engines" type="number"
                            value={formikWrap.values.engines}
                            onChange={formikWrap.handleChange}
                            onBlur={formikWrap.handleBlur}
                            error={formikWrap.touched.engines && Boolean(formikWrap.errors.engines)}
                            helperText={formikWrap.touched.engines && formikWrap.errors.engines}
                            fullWidth color="secondary"
                            variant="standard"
                        />
                        <TextField
                            margin="dense" id="comment" label="Additional comment" type="text"
                            value={formikWrap.values.comment}
                            onChange={formikWrap.handleChange}
                            onBlur={formikWrap.handleBlur}
                            error={formikWrap.touched.comment && Boolean(formikWrap.errors.comment)}
                            helperText={formikWrap.touched.comment && formikWrap.errors.comment}
                            fullWidth color="secondary"
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleAdd} color="secondary" type="submit">
                            Add limit
                        </Button>
                    </DialogActions>
                </Dialog>
          </span>
        );
      }

    function onDeleteLimits(limits: IUniqueLimits[]) {
        limits.forEach(limit => {
            if (!window.confirm("Are you sure you want to delete the limit: " + limit.id + " (" +  limit.limit.comment +")?")) {
                return;
            }

            fetchApi.run(window.location.origin + "/api/v2/limits/" + limit.id + "/" + limit.limit.ultimateSiteId,
                {
                    method: 'DELETE'
                })
                .then(() => { limitsApi.run(); })
        });
    }

    React.useEffect(() => {
        limitsApi.run();
    }, []);

    const pageSize = 15, pageSizeOptions = [5, 10, 15, 20];

    function limitsToUnique(userLimits: ILimits[])
    {
        const newLimits: IUniqueLimits[] = []
        userLimits.forEach(limits => {
            limits.limits.forEach(entLimit => {
                const unik: IUniqueLimits = { id: limits.id, limit: entLimit}
                newLimits.push(unik)
            })
        });

        return newLimits;
    }

    return (
        <div>
            <MaterialTable<IUniqueLimits>
                title={<div><Typography variant="h5">Limits&nbsp;{FormDialog()}</Typography></div>}
                isLoading={limitsApi.isFetching}
                columns={[
                    { title: "User Id", field: "id", sorting: true },
                    { title: "Site Id", field: "limit.ultimateSiteId" },
                    { title: "Max engines", field: "limit.maxEngines" },
                    { title: "Comment", field: "limit.comment" }
                ]}
                data={limitsToUnique(limitsApi.data ?? [])}
                actions={[
                    {
                        tooltip: 'Refresh',
                        icon: 'refresh',
                        isFreeAction: true,
                        onClick: () => limitsApi.run(),
                    },
                    ({
                        tooltip: 'Delete limit',
                        icon: 'delete',
                        onClick: (event, rowData) => onDeleteLimits(toArray(rowData))
                    })
                ]}
                options={{
                    actionsColumnIndex: -1,
                    search: true,
                    sorting: true,
                    pageSize: pageSize,
                    pageSizeOptions: pageSizeOptions,
                    headerStyle: { backgroundColor: '#9BA5AE', color: '#000000' },
                    padding: 'dense'
                }}
            />
        </div>
    );
}
