import * as React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/material';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { green as Green } from '@mui/material/colors';
import {styled} from '@mui/system'


const StyledTypography = styled(Typography)((props)=> ({
    flexGrow: 1,
    paddingLeft: props.theme.spacing(3),
    paddingRight: props.theme.spacing(1),
}))

const StyledAvatar = styled(Avatar)(()=>({
    backgroundColor: Green[400],  
}))

export default function ProfileAvatar() {

    const { oidcUser, logout } = useReactOidc();
    const { profile } = oidcUser;

    const [anchorEl, SetAnchorEl] = React.useState<any>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        SetAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        SetAnchorEl(null);
    };

    const initials = () => {
        return profile.given_name[0] + profile.family_name[0];
    }

    const open = Boolean(anchorEl);

    return (
        <div  style={{ width: 200 }}>
            <Grid container justifyContent="center" alignItems="center">

                <IconButton
                    aria-owns={open ? 'menu-appbar' : undefined}
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    size="large">
                    <StyledTypography
                        variant="subtitle1"
                        color="inherit"
                        noWrap
                    >
                        <Box component="div" display={{ xs: 'none', sm: 'block' }}>{profile.given_name} {profile.family_name}</Box>
                    </StyledTypography>
                    <StyledAvatar>{initials()}</StyledAvatar>
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem selected={true}>
                        <StyledTypography
                            variant="subtitle1"
                            color="inherit"
                            noWrap
                        >
                            {profile.name}
                        </StyledTypography>
                    </MenuItem>
                    <MenuItem selected={true}>
                        <StyledTypography
                            variant="subtitle2"
                            color="inherit"
                            noWrap
                        >
                            {profile.email}
                        </StyledTypography>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={logout}>Sign Out</MenuItem>
                </Menu>
            </Grid>
        </div>
    );
}
