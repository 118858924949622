import { DLQMessageCleanUp } from "realityservices-ui-components";

export default function ClearDLQMessages() {
    const queues: any[] = ["jobMessage", "nodeMessage"];

    function deleteFromDLQ(rowData: any) {
        return (
            window.location.origin +
            "/api/v2/servicebus/" +
            rowData.name +
            "/messageCount/" +
            rowData.dlqMessgeCount
        );
    }

    return (
        <div>
            <DLQMessageCleanUp
                queues={queues}
                title={"Queues"}
                controllerBaseUrl={
                    window.location.origin + "/api/v2/servicebus/"
                }
                getDeleteDlqMsgUrl={deleteFromDLQ}
            />
        </div>
    );
}
