import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { TextField, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useJobExamples, IDataCenterJobExamples } from './JobExamples';
import MenuButton from './MenuButton';
import CircularProgress from '@mui/material/CircularProgress';
import { useFetchApi } from '../utils/UseFetchApi'
import { IJob } from '../Model/Job';
import { IProject } from '../Model/Project';
import { styled } from '@mui/system';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`action-tabpanel-${index}`}
            aria-labelledby={`action-tab-${index}`}
            {...other}
        >
            <Box p={1}>{children}</Box>
        </Typography>
    );
}


const StyledDiv = styled('div')(()=> ({
    flexGrow: 1
}))

const StyledCenteredProgress = styled('div')(()=> ({
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
}))

const StyledTextField = styled(TextField)((props)=> ({
    backgroundColor: props.theme.palette.background.paper,
}))

const StyledButton = styled(Button)((props)=> ({
    marginLeft: props.theme.spacing(2),
    marginTop: props.theme.spacing(2),
}))

const StyledCircularProgress = styled(CircularProgress)((props)=> ({
    margin: props.theme.spacing(2),
}))


function CenteredProgress() {
    return (
        <StyledCenteredProgress>
            <StyledCircularProgress />
        </StyledCenteredProgress>
    );
}

function SubmitJobPanel(props: { jobExamples: IDataCenterJobExamples[] }) {
    const theme = useTheme();
    const [locationIndex, setLocationIndex] = React.useState(0);
    const [jobIndex, setJobIndex] = React.useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const [currentJob, setCurrentJob] = React.useState(JSON.stringify(props.jobExamples[0].jobs[0].job, null, '\t'));
    const fetchApi = useFetchApi<IJob>();
    const fetchProjectApi = useFetchApi<IProject>();

    function handleIndexChange(index: number) {
        setJobIndex(index);
        updateCurrentJob(locationIndex, index);
    }

    function onJobIndexChanged(index: number) {
        setJobIndex(index);
        updateCurrentJob(locationIndex, index);
    }

    function onLocationChanged(index: number) {
        setLocationIndex(index);
        setJobIndex(0);
        updateCurrentJob(index, 0);
    }

    function updateCurrentJob(locIndex: number, jIndex: number) {
        setCurrentJob(JSON.stringify(props.jobExamples[locIndex].jobs[jIndex].job, null, '\t'));
    }

    const onPayloadContentChanged = (i: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentJob(event.target.value);
    }

    function notifyError(message: string) {
        console.error(message);
        enqueueSnackbar(message, { variant: "error" });
    }

    async function createProject() {
        enqueueSnackbar("Creating Project: " + JSON.parse(currentJob).name, { variant: "info" });

        try {
            let body_project: string = "";
            if (JSON.parse(currentJob).projectId !== "99999999-9999-9999-9999-999999999999") {
                body_project = '{"name":"' + JSON.parse(currentJob).name + 
                '", "connectProjectId": "' + JSON.parse(currentJob).projectId + '"}'
            }
            else {
                body_project = '{"name":"' + JSON.parse(currentJob).name + '"}'
            }
            const project = await fetchProjectApi.run(window.location.origin + "/api/v2/projects",
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: body_project
                });


            if (!project)
                throw Error("unknown error");

            enqueueSnackbar("Project " + project.id + " successfully created!", { variant: "success" });

            return project;
        }
        catch (error) {
            let errorMessage = "Project create failed. ";
            if (error instanceof Error) {
                errorMessage += error.message;
            }
            notifyError(errorMessage);
        }
    }

    async function createJob(projectId: string) {
        let newJob = JSON.parse(currentJob) as IJob;
        newJob.projectId = projectId;

        enqueueSnackbar("Creating Job: " + newJob.name, { variant: "info" });
        try {
            const job = await fetchApi.run(window.location.origin + "/api/v2/jobs", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newJob)
            });
            if (!job)
                throw Error("unknown error");
            enqueueSnackbar("Job " + job.id + " successfully created!", { variant: "success" });

            return job;
        }
        catch (error) {
            let errorMessage = "Job create failed. ";
            if (error instanceof Error) {
                errorMessage += error.message;
            }
            notifyError(errorMessage);
        }
    }

    async function submitJob(id: string) {
        enqueueSnackbar("Submitting Job", { variant: "info" });

        try {
            const job = await fetchApi.run(window.location.origin + "/api/v2/jobs/" + id + "/submit", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            if (!job)
                throw Error("unknown error");
            enqueueSnackbar("Job " + job.id + " successfully submitted!", { variant: "success" });

            return job;
        }
        catch (error) {
            let errorMessage = "Job submit failed. ";
            if (error instanceof Error) {
                errorMessage += error.message;
            }
            notifyError(errorMessage);
        }
    }

    async function submitAsync() {
        try {

            const project = await createProject();
            if (!project)
                return;
            const job = await createJob(project.id);
            if (job)
                submitJob(job.id);
        }
        catch (error) {
            let errorMessage = "submitasync ";
            if (error instanceof Error) {
                errorMessage += error.message;
            }
            notifyError(errorMessage);
            // already logged
        }
    }

    function submit() {
        submitAsync()
            .catch(error => {
                console.error(error);
            });
    }


    return (
        <StyledDiv>
            <MenuButton key={locationIndex} index={locationIndex} choices={Array.from(props.jobExamples, l => l.dataCenter)} onChanged={onLocationChanged} />
            <MenuButton key={`${locationIndex}_${jobIndex}`} index={jobIndex} choices={Array.from(props.jobExamples[locationIndex].jobs, ex => ex.label)} onChanged={onJobIndexChanged} />
            <StyledButton variant="contained" color="secondary" onClick={submit}>
                Submit Job
                    </StyledButton>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={jobIndex}
                onChangeIndex={handleIndexChange}
            >
                {props.jobExamples[locationIndex].jobs.map((jobEx, index) =>
                    <TabPanel key={index} value={index} index={index} dir={theme.direction}>
                        <StyledTextField key={index}
                            id="job-payload"
                            label="Payload"
                            multiline
                            value={currentJob}
                            onChange={onPayloadContentChanged(index)}
                            margin="normal"
                            fullWidth={true}
                            variant="outlined"
                        />
                    </TabPanel>)}
            </SwipeableViews>
        </StyledDiv>
    );
}

export default function SubmitJob() {
    const jobExamples = useJobExamples();

    return (
        <StyledDiv>
            {jobExamples.data as IDataCenterJobExamples[] ? <SubmitJobPanel jobExamples={jobExamples.data as IDataCenterJobExamples[]} /> : <CenteredProgress />}
        </StyledDiv>);
}

