import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { JobLogDetails } from "./JobLogDetails";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export function JobLogsDialog({ isOpen, onClose, title, errors, warnings, size = "xl" }) {
    function handleClose(reason = "") {
        if (reason === "escapeKeyDown" || reason === "backdropClick")
            return;
        onClose();
    }
    return (_jsx("div", { children: _jsxs(Dialog, { onClose: (event, reason) => handleClose(reason), open: isOpen, maxWidth: size, fullWidth: true, sx: {
                ".MuiDialogTitle-root": {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                },
            }, children: [_jsxs(DialogTitle, { children: [title, _jsx(IconButton, { "aria-label": "close", onClick: () => handleClose(), children: _jsx(CloseIcon, {}) })] }), _jsx(DialogContent, { dividers: true, children: _jsx(JobLogDetails, { errors: errors, warnings: warnings, maxHeight: "55vh" }) })] }) }));
}
