import { InvalidJobsFromPools } from "realityservices-ui-components";

export default function InvalidJobsFromBatch() {
    const locationOptions = [
        { value: "EastUs", label: "East US" },
        { value: "CentralIndia", label: "Central India" },
        { value: "AustraliaEast", label: "Australia East" },
        { value: "NorthEurope", label: "North Europe" },
        { value: "SoutheastAsia", label: "Southeast Asia" },
        { value: "UKSouth", label: "UK South" },
        { value: "JapanEast", label: "Japan East" },
    ];

    function handleDelete(job: any) {
        return `${window.location.origin}/api/v2/clusters/${job.location}/${job.id}/terminate`;
    }

    return (
        <InvalidJobsFromPools
            getInvalidJobReportUrl={window.location.origin + "/api/v2/clusters/invalidJobs"}
            getCancelJobUrl={handleDelete}
            locationFilterOptions={locationOptions}
        />
    );
}
