import { useAuthorizationContext } from "../utils/AuthorizationContext";
import { hasAdminOrViewOnlyAccess } from "../utils/Utils";
import { HoursTimespan } from "./HoursTimespanSelector";
import { JobOutcomeStatistics as JobOutcomeStats } from "realityservices-ui-components";

export const timeOptions = [
    { value: HoursTimespan.LastHour, label: "Last hour" },
    { value: HoursTimespan.Last6Hours, label: "Last 6 hours" },
    { value: HoursTimespan.Last24Hours, label: "Last 24 hours" },
    { value: HoursTimespan.LastWeek, label: "Last week" },
    { value: HoursTimespan.Last2Weeks, label: "Last 2 weeks" },
    { value: HoursTimespan.LastMonth, label: "Last month" },
    { value: HoursTimespan.LastQuarter, label: "Last quarter" },
    { value: HoursTimespan.Last2Quarters, label: "Last 2 quarters" },
];

export default function JobOutcomeStatistics() {
    const frontendAccessLevel = useAuthorizationContext();
    const isAdminOrViewOnlyUser = hasAdminOrViewOnlyAccess(frontendAccessLevel);

    return (
        <JobOutcomeStats
            dataUrl={
                window.location.origin + "/api/v2/jobs/outcomeReportByType"
            }
            filterOptions={timeOptions}
            defaultFilterOption={{
                value: HoursTimespan.LastWeek,
                label: "Last week",
            }}
            showFailureAnalysisCharts={isAdminOrViewOnlyUser}
        />
    );
}
