import { AddBox } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextareaAutosize,
    Typography,
    styled,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import * as yup from "yup";
import { useFetchApi } from "../utils/UseFetchApi";
import { AuthorizationClient, AuthorizationClientUser } from "../Model/AccessInfo";

interface AddClientUserDialogProps {
    client: AuthorizationClient;
    onSubmit: () => void;
}

const emailValidationSchema = yup.object({
    emails: yup.string().required("client user emails are required"),
});

export default function AddClientUserDialog(props: AddClientUserDialogProps) {
    const [open, setOpen] = React.useState(false);
    const clientsApi = useFetchApi<AuthorizationClient>(
        `${window.location.origin}/api/v2/accounts/` + props.client.id
    );
    const [invalidEmails, setInvalidEmails] = useState<string[]>([]);

    const formik = useFormik({
        validateOnChange: true,
        validateOnBlur: true,
        initialValues: {
            emails: "",
        },
        validationSchema: emailValidationSchema,
        onSubmit: (values) => {
            const emails = values.emails
                .split(";")
                .map((email) => email.trim());
            alert(JSON.stringify(emails, null, 2));
        },
    });

    const handleClickOpen = () => {
        formik.resetForm();
        setInvalidEmails([]);
        setOpen(true);
    };

    const handleClose = () => {
        setInvalidEmails([]);
        setOpen(false);
    };

    const { enqueueSnackbar } = useSnackbar();

    const handleAdd = () => {
        formik.validateForm();
        if (!formik.isValid) return;

        const emailList = formik.values.emails
            .split(";")
            .map((email) => email.trim());

        const invalidEmails = emailList.filter(
            (email) => !yup.string().email().isValidSync(email)
        );

        if (invalidEmails.length > 0) {
            setInvalidEmails(invalidEmails);
            return;
        }
        setInvalidEmails([]);
        let users: AuthorizationClientUser[] = [...props.client.users];

        emailList.forEach((email) => {
            if (!users.some((user) => user.email === email)) {
                users.push({ email });
            }
        });
        setOpen(false);

        clientsApi
            .run(undefined, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ users: users }),
            })
            .then((res) => {
                if (res) {
                    enqueueSnackbar(`Added users successfully`, {
                        variant: "success",
                    });
                    props.onSubmit();
                }
            })
            .catch((error) => {
                enqueueSnackbar(`Failed to add users ${error}`, {
                    variant: "error",
                });
                console.error(error);
            });
    };

    return (
        <span>
            <Button
                startIcon={<AddBox />}
                variant="contained"
                color="secondary"
                size="small"
                style={{ textTransform: "none" }}
                disableElevation
                onClick={handleClickOpen}
            >
                Add users
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth
            >
                <DialogTitle id="form-dialog-title">
                    Add user emails to client : {props.client.name}
                </DialogTitle>
                <DialogContent>
                    <TextareaAutosize
                        maxRows={8}
                        minRows={4}
                        style={{ width: "100%", marginTop: "5px" }}
                        id="email"
                        name="emails"
                        placeholder="Enter semi-colon separated list of user emails"
                        value={formik.values.emails}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required
                    />
                    {invalidEmails && invalidEmails.length > 0 && (
                        <div style={{ maxHeight: "120px", overflowY: "auto" }}>
                            <Typography>
                                Please update/remove following invalid emails
                            </Typography>
                            <ul>
                                {invalidEmails.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleAdd}
                        type="submit"
                        variant="contained"
                        color="secondary"
                        style={{ textTransform: "none" }}
                        disabled={
                            formik.values.emails === "" ||
                            Boolean(formik.errors.emails)
                        }
                    >
                        Add users
                    </Button>
                    <Button
                        style={{ textTransform: "none" }}
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </span>
    );
}
