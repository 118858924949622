import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import { Legend, ResponsiveContainer, Tooltip } from "recharts";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from "recharts";
import { JobLogMessageProperty, } from "../../models/JobLog";
export function FailureAnalysisBarChart(props) {
    var _a, _b;
    return (_jsxs("div", { style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }, children: [props.title && (_jsx(Typography, { variant: "subtitle2", mb: 1, fontWeight: 400, children: props.title })), _jsx(ResponsiveContainer, { width: (_a = props.width) !== null && _a !== void 0 ? _a : "100%", height: (_b = props.height) !== null && _b !== void 0 ? _b : "100%", minHeight: "190px", minWidth: "200px", children: _jsxs(BarChart, { data: props.data, margin: {
                        top: 0,
                        right: 2,
                        left: 0,
                        bottom: 0,
                    }, children: [_jsx(CartesianGrid, { strokeDasharray: "3 3" }), _jsx(XAxis, { dataKey: props.type == JobLogMessageProperty.Category
                                ? JobLogMessageProperty.Category
                                : JobLogMessageProperty.Source, tick: { fontSize: 12 }, height: 20 }), _jsx(YAxis, { type: "number", allowDecimals: false, width: 35, tick: { fontSize: 12 } }), _jsx(Tooltip, { labelStyle: { fontSize: 12 }, itemStyle: { fontSize: 12 } }), props.showLegend && _jsx(Legend, {}), _jsx(Bar, { dataKey: "errors", fill: "#FB5252" }), _jsx(Bar, { dataKey: "warnings", fill: "#F6A21A" })] }) })] }));
}
