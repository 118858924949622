import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from "@mui/material";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, } from "recharts";
export function TopErrorsChart(props) {
    var _a, _b, _c, _d;
    const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FFC0CB"];
    return (_jsxs("div", { style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }, children: [props.title && (_jsx(Typography, { variant: "subtitle2", fontWeight: 400, children: props.title })), _jsx(ResponsiveContainer, { width: (_a = props.width) !== null && _a !== void 0 ? _a : "100%", height: (_b = props.height) !== null && _b !== void 0 ? _b : "100%", minHeight: "185px", minWidth: "200px", children: _jsxs(PieChart, { margin: {
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }, children: [_jsx(Pie, { data: (_c = props.data) !== null && _c !== void 0 ? _c : [], dataKey: "count", nameKey: "code", children: (_d = props.data) === null || _d === void 0 ? void 0 : _d.map((entry, index) => (_jsx(Cell, { fill: colors[index % colors.length] }, `cell-${index}`))) }), _jsx(Legend, { layout: "vertical", verticalAlign: "middle", align: "right", formatter: (value, entry, index) => (_jsx("span", { style: {
                                    color: "#7B7B7B",
                                    fontSize: "10px",
                                    wordBreak: "break-all",
                                }, children: value })) }), _jsx(Tooltip, { itemStyle: { fontSize: 12 } })] }) })] }));
}
