import { createContext, useContext } from "react";
import { FrontendAccessLevel } from "./Utils";

export const AuthorizationContext = createContext<FrontendAccessLevel>(FrontendAccessLevel.NoAccess);

export function useAuthorizationContext() {
    const frontendAccessLevel = useContext(AuthorizationContext);

    return frontendAccessLevel;
}
