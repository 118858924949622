import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/system';


const StyledButton = styled(Button)((props)=> ({
    marginLeft: props.theme.spacing(2),
    marginTop: props.theme.spacing(2),
}))

export default function MenuButton(props: { index: number, choices: string[], onChanged: any }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(props.index);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null)
    };

    const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        props.onChanged(index);
    };

    return (
        <React.Fragment>
            <StyledButton variant="contained" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style={{backgroundColor:"#e0e0e0"}}>
                {props.choices[selectedIndex]}
            </StyledButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {props.choices.map((choice, index) => (
                    <MenuItem key={index} onClick={event => handleMenuItemClick(event, index)}>{choice}</MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
}
