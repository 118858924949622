import * as React from 'react';
import MaterialTable, { Action } from '@material-table/core';
import { IJob, isJobActiveOrQueued, JobLink, JobOutcome, JobState, useJobActions, useJobReport } from '../Model/Job';
import { createDateColumn } from '../utils/DateUtils';
import { TextField, Typography, Button, Grid, FormControlLabel, Checkbox } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FilterList from '@mui/icons-material/FilterList';
import { useSnackbar } from 'notistack';
import { EmailEllipsis, exportToJson, hasAdminOrViewOnlyAccess } from '../utils/Utils';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useAuthorizationContext } from '../utils/AuthorizationContext';


export interface IJobOverviewProps {
    pageSize: number;
    pageSizeOptions: number[];
}

export default function JobsOverview({pageSize,pageSizeOptions}: IJobOverviewProps) {
    const { enqueueSnackbar } = useSnackbar();
    const jobActions = useJobActions();
    const jobReport = useJobReport();
    const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    const [elWidth, setelWidth] = React.useState(60);
    const [expanded, setExpanded] = React.useState(false);
    const [filter, setFilter] = React.useState({
        useFilter: false,

        jobIdTerm: "",
        ultimateSiteTerm: "",
        userEmailTerm: "",
        userIdTerm: "",

        filterNew: true,
        filterActive: true,
        filterQueued: true,
        filterSuccess: true,
        filterFailed: true,
        filterCancelled: true,

        filterByCreationDate: false,
        creationStartDate: new Date(Date.now() - thirtyDaysInMilliseconds),
        creationEndDate: new Date(),
       
        filterByStartDate: false,
        startStartDate: new Date(Date.now() - thirtyDaysInMilliseconds),
        startEndDate: new Date(),

        filterByEndDate: false,
        endStartDate: new Date(Date.now() - thirtyDaysInMilliseconds),
        endEndDate: new Date(),
    });
    const frontendAccessLevel = useAuthorizationContext();
    const isAdminOrViewOnlyUser = hasAdminOrViewOnlyAccess(frontendAccessLevel);


    function inclusiveStartDate(date: Date): Date {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        return (date);
    }

    function inclusiveEndDate(date: Date): Date {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return (date);
    }

    function getFilteredData(): IJob[] {
        var filteredJobs: IJob[] = [];
        if (jobReport.hasData)
            filteredJobs = jobReport.data!;

        if (filter.useFilter) {
            if (filter.jobIdTerm.length > 0)
                filteredJobs = filteredJobs.filter((job) => job.id.toLowerCase().includes(filter.jobIdTerm.toLowerCase()));
            if (filter.userEmailTerm.length > 0)
                filteredJobs = filteredJobs.filter((job) => job.userDetails.email.toLowerCase().includes(filter.userEmailTerm.toLowerCase()));
            if (filter.ultimateSiteTerm.length > 0)
                filteredJobs = filteredJobs.filter((job) => job.ultimateSite.toLowerCase().includes(filter.ultimateSiteTerm.toLowerCase()));
            if (filter.userIdTerm.length > 0)
                filteredJobs = filteredJobs.filter((job) => job.userDetails.id.toLowerCase().includes(filter.userIdTerm.toLowerCase()));

            filteredJobs = filteredJobs.filter((job) =>
                (job.state === JobState.New && filter.filterNew) ||
                (job.state === JobState.Active && filter.filterActive) ||
                (job.state === JobState.Queued && filter.filterQueued) ||
                (job.executionInformation?.outcome === JobOutcome.Success && filter.filterSuccess) ||
                (job.executionInformation?.outcome === JobOutcome.Failed && filter.filterFailed) ||
                (job.executionInformation?.outcome === JobOutcome.Cancelled && filter.filterCancelled));

            //apply date filters
            if (filter.filterByCreationDate) {
                filteredJobs = filteredJobs.filter((job) => new Date(job.creationTime) >= inclusiveStartDate(filter.creationStartDate));
                filteredJobs = filteredJobs.filter((job) => new Date(job.creationTime) <= inclusiveEndDate(filter.creationEndDate));
            }

            if (filter.filterByStartDate) {
                filteredJobs = filteredJobs.filter((job) => new Date(job.executionInformation?.startTime!) >= inclusiveStartDate(filter.startStartDate));
                filteredJobs = filteredJobs.filter((job) => new Date(job.executionInformation?.startTime!) <= inclusiveEndDate(filter.startEndDate));
            }

            if (filter.filterByEndDate) {
                filteredJobs = filteredJobs.filter((job) => new Date(job.executionInformation?.endTime!) >= inclusiveStartDate(filter.endStartDate));
                filteredJobs = filteredJobs.filter((job) => new Date(job.executionInformation?.endTime!) <= inclusiveEndDate(filter.endEndDate));
            }
        }

        return filteredJobs;
    }

    function refreshJobTimeSpan() {
        //find which of the start dates is the oldest
        var oldestDate: Date = (filter.creationStartDate.valueOf() < filter.startStartDate.valueOf()) ? filter.creationStartDate : filter.startStartDate;
        oldestDate = (oldestDate.valueOf() < filter.endStartDate.valueOf()) ? oldestDate : filter.endStartDate;

        //find which is the least
        var mostRecentDate: Date = (filter.creationEndDate.valueOf() > filter.startEndDate.valueOf()) ? filter.creationStartDate : filter.startStartDate;
        mostRecentDate = (mostRecentDate.valueOf() > filter.endEndDate.valueOf()) ? mostRecentDate : filter.endEndDate;

        jobReport.SetTimespan({
            startDate: oldestDate,
            endDate: mostRecentDate
        });
    }

    const handleTurnOnFilter = (props: any) => {
        setFilter({ ...filter, useFilter: true });
        refreshJobTimeSpan();
    };

    const handleTurnOffFilter = (props: any) => {
        setFilter({
            useFilter: false,

            jobIdTerm: "",
            ultimateSiteTerm: "",
            userEmailTerm: "",
            userIdTerm: "",

            filterNew: true,
            filterActive: true,
            filterQueued: true,
            filterSuccess: true,
            filterFailed: true,
            filterCancelled: true,

            filterByCreationDate: false,
            creationStartDate: new Date(Date.now() - thirtyDaysInMilliseconds),
            creationEndDate: new Date(),

            filterByStartDate: false,
            startStartDate: new Date(Date.now() - thirtyDaysInMilliseconds),
            startEndDate: new Date(),

            filterByEndDate: false,
            endStartDate: new Date(Date.now() - thirtyDaysInMilliseconds),
            endEndDate: new Date(),
        })
        jobReport.SetTimespan({
            startDate: new Date(Date.now() - thirtyDaysInMilliseconds),
            endDate: new Date()
        });
    };

    const handleFilterChange = (prop: any) => (event: { target: { value: any; }; }) => {
        setFilter({ ...filter, [prop]: event.target.value });
    };

    const handleCheckboxChange = (event: any) => {
        setFilter({ ...filter, [event.target.name]: event.target.checked });
    };

    const handleCreationStartDateChange = (date: any) => {
        if ((date as Date).getTime() > filter.creationEndDate.getTime()) {
            setFilter({ ...filter, creationStartDate: date, creationEndDate: date });
        }
        else {
            setFilter({ ...filter, creationStartDate: date});
        }
        if (filter.useFilter) {
            refreshJobTimeSpan();
        }
    };

    const handleCreationEndDateChange = (date: any) => {
        setFilter({ ...filter, creationEndDate: date });
        if (filter.useFilter) {
            refreshJobTimeSpan();
        }
    };

    const handleStartStartDateChange = (date: any) => {
        if ((date as Date).getTime() > filter.startEndDate.getTime()) {
            setFilter({ ...filter, startStartDate: date, startEndDate: date });
        }
        else {
            setFilter({ ...filter, startStartDate: date });
        }
        if (filter.useFilter) {
            refreshJobTimeSpan();
        }
    };

    const handleStartEndDateChange = (date: any) => {
        setFilter({ ...filter, startEndDate: date });
        if (filter.useFilter) {
            refreshJobTimeSpan();
        }
    };

    const handleEndStartDateChange = (date: any) => {
        if ((date as Date).getTime() > filter.endEndDate.getTime()) {
            setFilter({ ...filter, endStartDate: date, endEndDate: date });
        }
        else {
            setFilter({ ...filter, endStartDate: date});
        }
        if (filter.useFilter) {
            refreshJobTimeSpan();
        }
    };

    const handleEndEndDateChange = (date: any) => {
        setFilter({ ...filter, endEndDate: date });
        if (filter.useFilter) {
            refreshJobTimeSpan();
        }
    };

    const keyPress = (e: any) => {
        if (e.keyCode === 13) {
            handleTurnOnFilter(e);
        }
    }

    function stateFilters() {
        return (
            <Grid container spacing={1} style={{ width: 320 }} ml={0.4}>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox checked={filter.filterNew} onChange={handleCheckboxChange} name="filterNew" />}
                        label="New"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox checked={filter.filterActive} onChange={handleCheckboxChange} name="filterActive" />}
                        label="Active"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox checked={filter.filterQueued} onChange={handleCheckboxChange} name="filterQueued" />}
                        label="Queued"
                    />
                </Grid>
            </Grid>
        )
    }

    function outcomeFilters() {
        return (
            <Grid container spacing={1} style={{ width: 320 }} ml={0.4}>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox checked={filter.filterSuccess} onChange={handleCheckboxChange} name="filterSuccess" />}
                        label="Success"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox checked={filter.filterFailed} onChange={handleCheckboxChange} name="filterFailed" />}
                        label="Failed"
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox checked={filter.filterCancelled} onChange={handleCheckboxChange} name="filterCancelled" />}
                        label="Cancelled"
                    />
                </Grid>
            </Grid>
        )
    }

    function dateFilters() {
        return (
            <Grid container spacing={1} alignItems='center' style={{ width: 320 }}>
                <Grid item xs={2} md={2} lg={2} xl={2} alignContent='center'>
                    <Checkbox checked={filter.filterByCreationDate} onChange={handleCheckboxChange} name="filterByCreationDate" />
                </Grid>
                <Grid item xs={5} md={5} lg={5} xl={5}>
                   
                    <DatePicker
                        defaultValue={filter.creationStartDate}
                        label="Creation"
                        disabled={!filter.filterByCreationDate}
                        value = {filter.creationStartDate}
                        onChange={handleCreationStartDateChange}
                        format="MM/dd/yy"
                        slotProps={{
                            textField: {
                            variant: "standard",
                            }
                        }}
                        />
                </Grid>
                <Grid item xs={5} md={5} lg={5} xl={5}>
                    <DatePicker
                        defaultValue={filter.creationEndDate}
                        label=" "
                        disabled={!filter.filterByCreationDate}
                        value = {filter.creationEndDate}
                        onChange={handleCreationEndDateChange}
                        format="MM/dd/yy"
                        slotProps={{
                            textField: {
                            variant: "standard",
                            }
                        }}
                        />
                </Grid>
                <Grid item xs={2} alignContent='center' md={2} lg={2} xl={2}>
                    <Checkbox checked={filter.filterByStartDate} onChange={handleCheckboxChange} name="filterByStartDate" />
                </Grid>
                <Grid item xs={5} md={5} lg={5} xl={5}>
                        <DatePicker
                        defaultValue={filter.startStartDate}
                        label="Start"
                        disabled={!filter.filterByStartDate}
                        value = {filter.startStartDate}
                        onChange={handleStartStartDateChange}
                        format="MM/dd/yy"
                        slotProps={{
                            textField: {
                            variant: "standard",
                            }
                        }}
                        />
                   
                </Grid>
                <Grid item xs={5} md={5} lg={5} xl={5}>
                    <DatePicker
                        defaultValue={filter.startEndDate}
                        label=" "
                        disabled={!filter.filterByStartDate}
                        value = {filter.startEndDate}
                        onChange={handleStartEndDateChange}
                        format="MM/dd/yy"
                        slotProps={{
                            textField: {
                            variant: "standard",
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={2} alignContent='center' md={2} lg={2} xl={2}>
                    <Checkbox checked={filter.filterByEndDate} onChange={handleCheckboxChange} name="filterByEndDate" />
                </Grid>
                <Grid item xs={5}md={5} lg={5} xl={5}>
                    <DatePicker
                        defaultValue={filter.endStartDate}
                        label="End"
                        disabled={!filter.filterByEndDate}
                        value = {filter.endStartDate}
                        onChange={handleEndStartDateChange}
                        format="MM/dd/yy"
                        slotProps={{
                            textField: {
                            variant: "standard",
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={5} md={5} lg={5} xl={5}>
                    <DatePicker
                        defaultValue={filter.endEndDate}
                        label=" "
                        disabled={!filter.filterByEndDate}
                        value = {filter.endEndDate}
                        onChange={handleEndEndDateChange}
                        format="MM/dd/yy"
                        slotProps={{
                            textField: {
                            variant: "standard",
                            }
                        }}
                    />
                           
                       
                   
                </Grid>
            </Grid>
        )
    }

    function filterBar() {
        if (expanded) {
            return (
                <Grid container spacing={1} style={{ width: 320 }}>
                    <Grid item xs={4}>
                        <Typography variant="h5">Filters </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained" color="primary" disableElevation onClick={(event) => handleTurnOffFilter(event)}>
                            Clear
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained" color="secondary" disableElevation onClick={(event) => handleTurnOnFilter(event)}>
                            Search
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button color="primary" onClick={(event) => { setExpanded(false); setelWidth(60); }}>
                            <ChevronLeftIcon fontSize="large" />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={filter.jobIdTerm} fullWidth label="Job Id" type="search" size="small" variant="outlined" onKeyDown={keyPress} onChange={handleFilterChange('jobIdTerm')} />
                    </Grid>
                    {stateFilters()}
                    {outcomeFilters()}
                    {dateFilters()}
                    <Grid item xs={12}>
                        <TextField value={filter.userEmailTerm} fullWidth label="Email" type="search" size="small" variant="outlined" onKeyDown={keyPress} onChange={handleFilterChange('userEmailTerm')} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={filter.ultimateSiteTerm} fullWidth label="Ultimate Site" size="small" type="search" variant="outlined" onKeyDown={keyPress} onChange={handleFilterChange('ultimateSiteTerm')} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={filter.userIdTerm} fullWidth label="User Id" type="search" size="small" variant="outlined" onKeyDown={keyPress} onChange={handleFilterChange('userIdTerm')} />
                    </Grid>
                </Grid>
            )
        }
        else {
            return (
                <Grid container spacing={0} style={{ width: 40 }}>
                    <Grid item xs={12}>
                        <Button color="primary" onClick={(event) => { setExpanded(true); setelWidth(350); }}>
                            <FilterList fontSize="large" />
                        </Button>
                    </Grid>
                </Grid>
            )
        }
    }

    function exportFilteredDataToJson() {
        try {
            var jsonString = JSON.stringify(getFilteredData() ?? []);
            exportToJson(jsonString, `Jobs_${new Date().getTime()}.json`);
        } catch(err: any) {
            enqueueSnackbar(`Failed to export! Error: ${err.message}`, { variant: "error" });
        }
    }

    function getTableActions(){
        let actions: (Action<IJob> | ((rowData: IJob) => Action<IJob>))[] = [
            {
                tooltip: 'Refresh jobs',
                icon: 'refresh',
                isFreeAction: true,
                onClick: () => jobReport.refresh()
            },
        ]

        if (isAdminOrViewOnlyUser) {
            actions.push(
                (rowData) => ({
                    tooltip:
                        rowData.state !== JobState.New
                            ? "Download logs"
                            : undefined,
                    icon: "get_app",
                    disabled: rowData.state === JobState.New,
                    onClick: (event, rowData) =>
                        jobActions.downloadLogs(rowData),
                }),
                (rowData) => ({
                    tooltip:
                        isJobActiveOrQueued(rowData)
                            ? "Terminate Job"
                            : undefined,
                    icon: "cancel",
                    disabled: !isJobActiveOrQueued(rowData),
                    onClick: (event, rowData) => jobActions.terminate(rowData),
                })
            );
        }
        return actions;
    }

    return (
        <div>
            <Grid container  direction="row" justifyContent="space-evenly" spacing={1}>
                <Grid item mt={1} mx={0.7}>
                    {filterBar()}
                </Grid>
                <Grid item  style={{width: `calc(100% - ${elWidth}px)`}} mt={0.7}>
                    <MaterialTable<IJob>
                        title={<div><Typography variant="h5">Jobs</Typography></div>}
                        isLoading={jobReport.isFetching}
                        columns={[
                            { title: "Name", field: "name", sorting: true, render: rowData => <JobLink id={rowData.id} name={rowData.name} /> },
                            { title: "Type", field: "type", sorting: true },
                            { title: "State", field: "state", cellStyle: { textTransform: 'capitalize' } },
                            { title: "Outcome", field: "executionInformation.outcome" as any, sorting: true, cellStyle: { textTransform: 'capitalize' } },
                            {
                                title: "Email", field: "userDetails.email" as any, sorting: true,
                                render: (rowData: IJob) => <EmailEllipsis email={rowData.userDetails.email} />
                            },
                            createDateColumn("Creation", "creationTime"),
                            createDateColumn("Start", "executionInformation.startTime"),
                            createDateColumn("End", "executionInformation.endTime", 'desc'),
                            { title: "Location", field: "dataCenter.location" as any, sorting: true },
                        ]}
                        data={getFilteredData() ?? []}
                        actions={getTableActions()}
                        options={{
                            filtering: false,
                            pageSize: pageSize,
                            pageSizeOptions: pageSizeOptions,
                            search: true,
                            sorting: true,
                            headerStyle: { backgroundColor: '#9BA5AE', color: '#000000' },
                            padding: 'dense',
                            exportAllData: true,
                            exportMenu: [{
                                label: 'Export to JSON',
                                exportFunc: (cols, datas) => exportFilteredDataToJson()
                            }]
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );

}


