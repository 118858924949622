import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import theme from "./utils/Theme";
import { SnackbarProvider } from "notistack";
import { ConfigLoader } from "./components/ConfigLoader";
import { Authenticate } from "./components/Authenticate";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CssBaseline from "@mui/material/CssBaseline";
import { Stack } from "@mui/material";
import AppHeader from "./components/AppHeader";
import { styled } from "@mui/system";
import AppContent from "./components/AppContent";

const StyledDivRoot = styled("div")(() => ({
    flexGrow: 1,
}));


export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <ConfigLoader>
                <Authenticate>
                    <SnackbarProvider maxSnack={3}>
                        <StyledEngineProvider injectFirst>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <BrowserRouter>
                                    <CssBaseline />
                                    <StyledDivRoot>
                                        <Stack>
                                            <AppHeader />
                                            <Stack direction="row">
                                                <AppContent />
                                            </Stack>
                                        </Stack>
                                    </StyledDivRoot>
                                </BrowserRouter>
                            </LocalizationProvider>
                        </StyledEngineProvider>
                    </SnackbarProvider>
                </Authenticate>
            </ConfigLoader>
        </ThemeProvider>
    );
}
