import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { styled } from '@mui/system';

export interface ITokenState {
    token: string;
    tokenValue: string;
}

const StyledForm = styled('form')(()=> ({
    display: 'flex',
    flexWrap: 'wrap'
}))

const StyledTextField = styled(TextField)((props)=> ({
    width: '100vw',
    marginLeft: props.theme.spacing(1),
    marginRight: props.theme.spacing(1)
}))

const StyledButton = styled(Button)((props)=> ({
    margin: props.theme.spacing(1)
}))

export default function CopyToken() {
    const { oidcUser } = useReactOidc();
    const { enqueueSnackbar } = useSnackbar();

    const tokenValue = oidcUser.access_token
    const token = "Bearer " + tokenValue;

    return (
        <div>
            <StyledForm  noValidate autoComplete="off">
                <StyledTextField
                    id="outlined-multiline-static"
                    label="Token"
                    multiline
                    rows="12"
                    value={token}
                    margin="normal"
                    variant="outlined"
                    InputProps={{ readOnly: true }}
                />
                <CopyToClipboard text={tokenValue}
                    onCopy={() => enqueueSnackbar("Token value copied!", { variant: "success" })}>
                    <StyledButton variant="contained" color="secondary" >
                        Copy Value
                        </StyledButton>
                </CopyToClipboard>
                <CopyToClipboard text={token}
                    onCopy={() => enqueueSnackbar("Token copied!", { variant: "success" })}>
                    <StyledButton variant="contained" color="secondary" >
                        Copy Token
                        </StyledButton>
                </CopyToClipboard>
            </StyledForm>
        </div>
    );
}
