import { useEffect, useState } from "react";
import MaterialTable from "@material-table/core";
import {
    Select,
    MenuItem,
    styled,
    FormControl,
    Button,
    TextField,
} from "@mui/material";
import AddClientUserDialog from "./AddClientUserDialog";
import { useFetchApi } from "../utils/UseFetchApi";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { AuthorizationClient, AuthorizationClientUser } from "../Model/AccessInfo";

const StyledFormControl = styled(FormControl)((props) => ({
    minWidth: 120,
    backgroundColor: props.theme.palette.background.paper,
}));

const StyledSelect = styled(Select)((props) => ({
    backgroundColor: props.theme.palette.background.paper,
    color: props.theme.palette.text.secondary,
}));

const StyledButton = styled(Button)((props) => ({
    marginLeft: props.theme.spacing(2),
    marginTop: props.theme.spacing(2),
    width: "fit-content",
}));

const emailValidationSchema = yup.object({
    email: yup.string().email("please enter a valid email").required(),
});

function EditableEmail(props: { onChange: (arg0: any) => void; value: any }) {
    const [error, setError] = useState<string>("");

    const validateEmail = async (email: string) => {
        try {
            await emailValidationSchema.validate({ email });
            setError('');
        } catch (validationError) {
            if (validationError instanceof yup.ValidationError)
                setError(validationError.message);
        }
    };

    return (
        <TextField
            margin="dense"
            id="email"
            type="email"
            value={props.value}
            onChange={e => {
                props.onChange(e.target.value);
                validateEmail(e.target.value);
            }}
            fullWidth
            required
            variant="standard"
            error={error !== ""}
            helperText={error}
        />
    );
}

const ManageAccess = () => {
    const { data, run, isFetching, error } = useFetchApi<AuthorizationClient[]>(
        `${window.location.origin}/api/v2/accounts/clients`
    );
    const {
        data: clientData,
        run: clientApiRun,
        isFetching: isClientApiFetching,
        error: clientApiError,
    } = useFetchApi<AuthorizationClient>();
    const [selectedClient, setSelectedClient] = useState<AuthorizationClient>();

    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [updatedClientUsers, setUpdatedClientUsers] =
        useState<AuthorizationClientUser[]>();

    useEffect(() => {
        run().then((clients) => {
            if (clients && clients.length > 0) {
                setSelectedClient(
                    clients.sort((a, b) => a.name.localeCompare(b.name))[0]
                );
            }
        });
    }, []);

    useEffect(() => {
        if (selectedClient) {
            updateClientData();
        }
    }, [selectedClient?.id]);

    const handleClientChange = (event: any) => {
        const client = data?.find(
            (c) => c.id === (event.target.value as string)
        );
        if (client) setSelectedClient(client);
    };

    const { enqueueSnackbar } = useSnackbar();

    const handleSaveChanges = async () => {
        if (
            JSON.stringify(updatedClientUsers) !==
            JSON.stringify(selectedClient?.users)
        ) {
            clientApiRun(
                `${window.location.origin}/api/v2/accounts/${selectedClient?.id}`,
                {
                    method: "PATCH",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ users: updatedClientUsers }),
                }
            )
                .then((client) => {
                    if (client) {
                        enqueueSnackbar(`Changes saved successfully`, {
                            variant: "success",
                        });
                        setUpdatedClientUsers(client.users);
                    }
                })
                .catch((error) => {
                    enqueueSnackbar(`Failed to update ${error}`, {
                        variant: "error",
                    });
                    console.error(error);
                });

            setUnsavedChanges(false);
        }
    };

    function updateClientData() {
        if (selectedClient) {
            clientApiRun(
                `${window.location.origin}/api/v2/accounts/${selectedClient.id}`
            ).then((client) => {
                if (client) {
                    setSelectedClient(client);
                    setUpdatedClientUsers(client.users);
                    setUnsavedChanges(false);
                }
            });
        }
    }

    const handleAddUser = () => {
        //fetch current client data since users are updated
        updateClientData();
    };

    const handleAddClient = () => {
        //fetch all clients data since new client is added
        run();
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "10px",
            }}
        >
            <MaterialTable
                title={
                    <div style={{ display: "flex", gap: "15px", alignItems:"end" }}>
                        <StyledFormControl variant="standard">
                            <StyledSelect
                                value={selectedClient ? selectedClient.id : ""}
                                onChange={handleClientChange}
                            >
                                {data
                                    ?.sort((a, b) =>
                                        a.name.localeCompare(b.name)
                                    )
                                    .map((client) => (
                                        <MenuItem
                                            key={client.id}
                                            value={client.id}
                                        >
                                            {client.name}
                                        </MenuItem>
                                    ))}
                            </StyledSelect>
                        </StyledFormControl>
                        {clientData && (
                            <AddClientUserDialog
                                client={clientData}
                                onSubmit={handleAddUser}
                            />
                        )}
                    </div>
                }
                columns={[
                    {
                        title: "Email",
                        field: "email",
                        editComponent: (props) => <EditableEmail {...props} />,
                    },
                ]}
                isLoading={isFetching || isClientApiFetching}
                data={updatedClientUsers ?? []}
                style={{ width: "100%" }}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30, 40, 50],
                    search: true,
                    headerStyle: {
                        backgroundColor:
                            error || clientApiError ? "#FF0000" : "#9BA5AE",
                        color: "#000000",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                    padding: "dense",
                    maxBodyHeight: "65vh",
                    rowStyle: {
                        fontSize: "0.875rem",
                    },
                }}
                editable={{
                    onRowUpdate: (newData, oldData) =>
                        new Promise<void>((resolve, reject) => {
                            // extracting only email, material table adds additional table data prop to track changes
                            const updatedData = { email: newData.email };

                            emailValidationSchema
                                .validate({ email: newData.email })
                                .then(() => {
                                    const users = updatedClientUsers?.map(
                                        (user) =>
                                            user.email === oldData?.email
                                                ? updatedData
                                                : user
                                    );
                                    setUpdatedClientUsers(users);

                                    setUnsavedChanges(
                                        JSON.stringify(users) !==
                                        JSON.stringify(
                                            selectedClient?.users
                                        )
                                    );
                                    resolve();
                                })
                                .catch((validationError) => {
                                    console.error(validationError.message);
                                    reject();
                                });
                        }),
                    onRowDelete: (oldData) =>
                        new Promise<void>((resolve, reject) => {
                            const updatedUsers = updatedClientUsers?.filter(
                                (user) => user.email !== oldData.email
                            );
                            setUpdatedClientUsers(updatedUsers);
                            setUnsavedChanges(
                                JSON.stringify(updatedUsers) !==
                                JSON.stringify(selectedClient?.users)
                            );
                            resolve();
                        }),
                }}
            />
            <StyledButton
                variant="contained"
                onClick={handleSaveChanges}
                disabled={!unsavedChanges}
                color="secondary"
                style={{ textTransform: "none" }}
            >
                Save Changes
            </StyledButton>
        </div>
    );
};

export default ManageAccess;
