import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import MaterialTable from "@material-table/core";
import moment from "moment";
import useInterval from "../../utils/UseInterval";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { useFetchApi } from "../../utils/UseFetchApi";
import { dateToISOWithReducedPrecision } from "../../utils/DateUtils";
import { Selector } from "../Selector";
import { FailureAnalysisBarChart } from "./FailureAnalysisBarChart";
import { TopErrorsChart } from "./TopErrorsChart";
import { JobLogMessageCategory, JobLogMessageProperty, JobLogMessageSource, } from "../../models/JobLog";
import { Legend } from "recharts";
export function JobOutcomeStatistics(props) {
    var _a, _b, _c;
    const [filterCriteriaValue, setFilterCriteriaValue] = React.useState(props.defaultFilterOption.value);
    const [autoRefresh, setAutoRefresh] = React.useState((_a = props.autoRefresh) !== null && _a !== void 0 ? _a : false);
    const jobOutcomeStats = useFetchApi(`${props.dataUrl}?fromDateTime=${getTimespanUrl(filterCriteriaValue)}`);
    React.useEffect(() => {
        jobOutcomeStats.run();
    }, [filterCriteriaValue]);
    function getTimespanUrl(timeSpan) {
        var startEndDate = new Date(moment()
            .subtract(timeSpan, "hours")
            .toDate());
        return dateToISOWithReducedPrecision(startEndDate);
    }
    function onTimespanChanged(newTimespan) {
        setFilterCriteriaValue(newTimespan);
    }
    function toggleAutoRefresh() {
        if (autoRefresh) {
            setAutoRefresh(false);
        }
        else {
            setAutoRefresh(true);
            jobOutcomeStats.run();
        }
    }
    useInterval(() => {
        if (jobOutcomeStats.error) {
            setAutoRefresh(false);
            return;
        }
        jobOutcomeStats.run();
    }, autoRefresh ? 10000 : null);
    return (_jsxs("div", { style: { height: "100%", width: "100%" }, children: [_jsx("div", { style: { marginBottom: "10px" }, children: _jsx(MaterialTable, { title: _jsx(Selector, { default: props.defaultFilterOption, onValueChanged: onTimespanChanged, options: props.filterOptions }), isLoading: jobOutcomeStats.fetchCount === 0, columns: [
                        { title: "Job type", field: "jobType", sorting: false },
                        {
                            title: "Success",
                            field: "successCount",
                            sorting: false,
                            align: "right"
                        },
                        {
                            title: "Failed",
                            field: "failedCount",
                            sorting: false,
                            align: "right"
                        },
                        {
                            title: "Cancelled",
                            field: "cancelledCount",
                            sorting: false,
                            align: "right"
                        },
                        {
                            title: "Total",
                            field: "totalCount",
                            sorting: false,
                            align: "right"
                        }
                    ], data: (_c = (_b = jobOutcomeStats.data) === null || _b === void 0 ? void 0 : _b.jobTypeOutcomeStatistics) !== null && _c !== void 0 ? _c : [], renderSummaryRow: jobOutcomeStats.fetchCount > 0
                        ? ({ column, data }) => {
                            let value;
                            if (column.field === "jobType") {
                                value = "Total";
                            }
                            else {
                                value = data.reduce((acc, d) => acc + d[column.field], 0);
                            }
                            return {
                                value,
                                style: {
                                    fontWeight: 500,
                                    padding: "6px 16px"
                                }
                            };
                        }
                        : undefined, actions: [
                        {
                            icon: props.autoRefresh
                                ? () => autoRefresh ? (_jsx(PauseCircleOutlineIcon, {})) : (_jsx(PlayCircleOutlineIcon, {}))
                                : "refresh",
                            tooltip: props.autoRefresh ? "Auto Refresh" : "Refresh",
                            isFreeAction: true,
                            onClick: () => props.autoRefresh ? toggleAutoRefresh() : jobOutcomeStats.run()
                        }
                    ], options: {
                        filtering: false,
                        headerStyle: {
                            backgroundColor: jobOutcomeStats.error ? "#FF0000" : "#9BA5AE",
                            color: "#000000",
                            maxWidth: 25,
                            width: 25
                        },
                        padding: "dense",
                        rowStyle: {
                            fontSize: "0.875rem"
                        },
                        search: false,
                        paging: false,
                        maxBodyHeight: 400,
                        sorting: false
                    } }) }), props.showFailureAnalysisCharts &&
                jobOutcomeStats.data &&
                jobOutcomeStats.data.jobFailureStatistics &&
                jobOutcomeStats.data.jobTypeOutcomeStatistics.some((statistic) => statistic.failedCount > 0) && (_jsxs("div", { style: { height: "100%", width: "100%" }, children: [_jsx(TopErrorsChart, { data: Object.entries(jobOutcomeStats.data.jobFailureStatistics.errorsStatistics
                            .topErrors).map(([key, value]) => ({ code: key, count: value })), title: "Top 5 errors" }), _jsxs("div", { style: {
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                            gap: "10px"
                        }, children: [_jsx(FailureAnalysisBarChart, { type: JobLogMessageProperty.Category, data: [
                                    {
                                        category: JobLogMessageCategory.User,
                                        errors: jobOutcomeStats.data.jobFailureStatistics.errorsStatistics
                                            .user,
                                        warnings: jobOutcomeStats.data.jobFailureStatistics
                                            .warningsStatistics.user
                                    },
                                    {
                                        category: JobLogMessageCategory.Service,
                                        errors: jobOutcomeStats.data.jobFailureStatistics.errorsStatistics
                                            .service,
                                        warnings: jobOutcomeStats.data.jobFailureStatistics
                                            .warningsStatistics.service
                                    }
                                ], title: "Failure caused by" }), _jsx(FailureAnalysisBarChart, { type: JobLogMessageProperty.Source, data: [
                                    {
                                        source: JobLogMessageSource.Engine,
                                        errors: jobOutcomeStats.data.jobFailureStatistics.errorsStatistics
                                            .engine,
                                        warnings: jobOutcomeStats.data.jobFailureStatistics
                                            .warningsStatistics.engine
                                    },
                                    {
                                        source: JobLogMessageSource.WorkerApp,
                                        errors: jobOutcomeStats.data.jobFailureStatistics.errorsStatistics
                                            .workerApp,
                                        warnings: jobOutcomeStats.data.jobFailureStatistics
                                            .warningsStatistics.workerApp
                                    }
                                ], title: "Failure source app" })] }), _jsx(Legend, { wrapperStyle: { position: "relative" }, formatter: (value, entry, index) => (_jsx("span", { style: { color: "#7B7B7B", fontSize: "10px" }, children: value })), payload: [
                            { value: "Errors", type: "square", color: "#FB5252" },
                            { value: "Warnings", type: "square", color: "#FFA630" }
                        ], iconSize: 10 })] }))] }));
}
