import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';


const StyledCircularProgress = styled(CircularProgress)((props)=> ({
    margin: props.theme.spacing(2) 
}))

const StyledDiv = styled('div')(()=> ({
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center'
}))

export default function CenteredProgress() {
    return (
        <StyledDiv >
            <StyledCircularProgress />
        </StyledDiv>
    );
}