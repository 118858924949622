import React, { useState } from 'react';
import { Paper } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ActiveJobs from './ActiveJobs';
import CompletedJobsFilter from './CompletedJobsFilter';

 interface IProps {
    dataUrl: string,
    actionUrl: string
 }


export default function DashboardJobsOverview(props:IProps){
    const [value, setValue] = React.useState(0); 
    const [tabLoad,setTabLoad]= React.useState([true,false,false,false]);

    const handleTabChange = (event:React.SyntheticEvent, newValue:number) => {
        setValue(newValue);
        setTabLoad(prevState => {
            const newState = [...prevState];
            newState[newValue] = true; 
            return newState;
        });
    };

    return(
            <Paper sx={{p:1}}>
                        
                <Tabs value={value} onChange={handleTabChange} textColor="secondary" indicatorColor="secondary" sx={{pb:1}}>
                    <Tab label="Active jobs" sx={{textTransform: "none",fontSize:"1rem"}}/>
                    <Tab label="Failed jobs" sx={{textTransform: "none",fontSize:"1rem"}}/>
                    <Tab label="Cancelled jobs" sx={{textTransform: "none",fontSize:"1rem"}}/>
                    <Tab label="Successful jobs" sx={{textTransform: "none",fontSize:"1rem"}}/>
                </Tabs>

                            
                <div style={{ display: value === 0? 'block': 'none'}}>
                    {tabLoad[0] && <ActiveJobs toggleTabRefresh={value===0} /> }
                </div>
                <div style={{ display: value === 1? 'block': 'none'}} >
                    {tabLoad[1] &&  <CompletedJobsFilter toggleTabRefresh={value===1} jobFilter='outcome=Failed'/>}
                </div>
                <div style={{ display: value === 2? 'block': 'none'}} >
                    {tabLoad[2] && <CompletedJobsFilter toggleTabRefresh={value===2} jobFilter='outcome=Cancelled'/>}
                </div>
                <div style={{ display: value === 3? 'block': 'none'}} >
                    {tabLoad[3] && <CompletedJobsFilter toggleTabRefresh={value===3} jobFilter='outcome=Success'/> }
                </div>
        </Paper>
    )            
}