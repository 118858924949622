import styled from "@emotion/styled";
import DrawerList from "./DrawerList";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { useAuthorizationContext } from "../utils/AuthorizationContext";
import Dashboard from "./Dashboard";
import JobDetails from "./JobDetails";
import JobsOverview from "./JobsOverview";
import { FrontendAccessLevel, hasAdminOrViewOnlyAccess } from "../utils/Utils";
import ClusterDetails from "./ClusterDetails";
import Clusters from "./Clusters";
import Limits from "./Limits";
import CopyToken from "./CopyToken";
import SubmitJob from "./SubmitJob";
import CheckUserAccess from "./CheckUserAccess";
import Statistics from "./Statistics";
import Reporting from "./Reporting";
import Profitability from "./Profitability";
import StartTimeDelay from "./StartTimeDelay";
import InvalidJobsFromBatch from "./InvalidJobsFromPools";
import ClearDLQMessages from "./DLQMessageCleanUp";
import ManageAccess from "./ManageAccess";
import AccessDenied from "./AccessDenied";

const StyledDivContent = styled("div")(() => ({
    flexGrow: 1,
    height: "100%",
    width: "80%",
}));

const StyledDivContentRoot = styled("div")(() => ({
    display: "flex",
    width: "100%",
}));

export default function AppContent() {
    const frontendAccessLevel = useAuthorizationContext();
    const isAdminOrViewOnlyUser = hasAdminOrViewOnlyAccess(frontendAccessLevel);
    const location = useLocation();

    return (
        <StyledDivContentRoot>
            {location.pathname !== "/access-denied" && <DrawerList />}
            <StyledDivContent>
                <Routes>
                    <Route
                        path="/"
                        element={<Navigate to="/admin/dashboard" />}
                    />
                    <Route path="/admin/dashboard" element={<Dashboard />} />
                    <Route path="/admin/jobs/:id" element={<JobDetails />} />
                    <Route
                        path="/admin/jobs"
                        element={
                            <JobsOverview
                                {...{
                                    pageSize: 20,
                                    pageSizeOptions: [10, 20, 30],
                                }}
                            />
                        }
                    />

                    {isAdminOrViewOnlyUser && (
                        <>
                            <Route
                                path="/admin/clusters/:location/:name"
                                element={<ClusterDetails />}
                            />
                            <Route
                                path="/admin/clusters"
                                element={<Clusters />}
                            />
                            <Route path="/admin/limits" element={<Limits />} />
                            <Route
                                path="/admin/tools/copytoken"
                                element={<CopyToken />}
                            />
                            <Route
                                path="/admin/tools/submitjob"
                                element={<SubmitJob />}
                            />
                            <Route
                                path="/admin/tools/checkuseraccess"
                                element={<CheckUserAccess />}
                            />
                            <Route
                                path="/admin/statistics"
                                element={<Statistics />}
                            />
                            <Route
                                path="/admin/billing/reporting"
                                element={
                                    <Reporting
                                        {...{
                                            pageSize: 20,
                                            pageSizeOptions: [10, 20, 30],
                                        }}
                                    />
                                }
                            />
                            <Route
                                path="/admin/billing/profitability"
                                element={
                                    <Profitability
                                        {...{
                                            pageSize: 20,
                                            pageSizeOptions: [10, 20, 30],
                                        }}
                                    />
                                }
                            />
                            <Route
                                path="/admin/billing/starttimedelay"
                                element={<StartTimeDelay />}
                            />
                            <Route
                                path="/admin/azureResources/invalidjobsfrompools"
                                element={<InvalidJobsFromBatch />}
                            />
                            <Route
                                path="/admin/azureResources/dlqmessages"
                                element={<ClearDLQMessages />}
                            />
                            <Route
                                path="/admin/manage-access"
                                element={<ManageAccess />}
                            />
                        </>
                    )}
                    <Route path="*" element={<Navigate to="/access-denied" />} />
                    <Route path="/access-denied" element={<AccessDenied />} />
                </Routes>
            </StyledDivContent>
        </StyledDivContentRoot>
    );
}
