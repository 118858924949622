import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MaterialTable from "@material-table/core";
import { JobLogType } from "../../models/JobLog";
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from "@mui/material/Tooltip";
import React from "react";
export function JobLogDetails({ errors, warnings, maxHeight }) {
    const [logDetails, setLogDetails] = React.useState([]);
    React.useEffect(() => {
        const processDetails = (details, messageType) => {
            return details.flatMap((detail) => (detail.messages || [])
                .filter((message) => message)
                .map((message) => ({
                taskId: detail.taskId,
                message,
                type: messageType,
            })));
        };
        const logDetails = [
            ...(errors ? processDetails(errors, JobLogType.Error) : []),
            ...(warnings ? processDetails(warnings, JobLogType.Warning) : []),
        ];
        setLogDetails(logDetails);
    }, []);
    return (_jsx("div", { children: _jsx(MaterialTable, { title: "", columns: [
                {
                    title: "Task ID",
                    field: "taskId",
                    width: "7%",
                },
                {
                    title: "Code",
                    field: "message.code",
                    render: (rowData) => (_jsxs("div", { style: {
                            display: "flex",
                            alignItems: "center",
                            wordBreak: "break-word",
                        }, children: [rowData.type === JobLogType.Error && (_jsx(Tooltip, { title: "Error", children: _jsx(ErrorIcon, { style: {
                                        color: "red",
                                        marginRight: 8,
                                    } }) })), rowData.type === JobLogType.Warning && (_jsx(Tooltip, { title: "Warning", children: _jsx(WarningIcon, { style: {
                                        color: "orange",
                                        marginRight: 8,
                                    } }) })), _jsx("span", { children: rowData.message.code })] })),
                },
                {
                    title: "Title",
                    field: "message.title",
                },
                {
                    title: "Message",
                    field: "message.message",
                },
                {
                    title: "Source app",
                    field: "message.source",
                    width: "7%",
                },
                {
                    title: "Caused by",
                    field: "message.category",
                    width: "7%",
                },
            ], data: logDetails, options: {
                pageSize: 10,
                paging: true,
                search: true,
                padding: "dense",
                headerStyle: {
                    backgroundColor: "#9BA5AE",
                    color: "#000000",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                },
                rowStyle: {
                    fontSize: "0.875rem",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                },
                maxColumnSort: 0,
                maxBodyHeight: maxHeight !== null && maxHeight !== void 0 ? maxHeight : "40vh",
            } }) }));
}
