import * as React from 'react';
import { Bar, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Label, ComposedChart, CartesianGrid, Legend } from 'recharts';
import { useFetchApi } from '../utils/UseFetchApi';
import { Grid, Button, TextField, Box, Paper } from '@mui/material';
import { useSnackbar } from 'notistack';
import { dateToISOWithReducedPrecision } from '../utils/DateUtils';
import { IJob, JobOutcome } from '../Model/Job';
import Title from './Title';
import CenteredProgress from './CenteredProgress';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material';


const StyledPaper = styled(Paper)((props)=> ({
    padding: props.theme.spacing(2),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 500,
}))

const StyledForm = styled('form')(()=> ({
    display: 'flex',
    flexWrap: 'wrap'
}))

const StyledTextField = styled(TextField)((props)=> ({
    width: '100vw',
    marginLeft: props.theme.spacing(1),
    marginRight: props.theme.spacing(1)
}))

const StyledButton = styled(Button)((props)=> ({
    margin: props.theme.spacing(1)
}))

// Number of jobs (and percentage) that have started their execution less than 'delay' minutes after creation
// if delay is 20, then we get all jobs that have taken more that 19 minutes to start
interface IJobTimeStats {
    delay: number,
    count: number,
    percentage: string
}

export default function StartTimeDelay() {
    const { enqueueSnackbar } = useSnackbar();
    const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000;
    const [ultimateSiteId, setUltimateSiteId] = React.useState("");
    const [startDate, setStartDate] = React.useState(new Date(Date.now() - thirtyDaysInMilliseconds));
    const [endDate, setEndDate] = React.useState(new Date(Date.now()));
    const fetchApi = useFetchApi<IJob[]>();

    const [average, setAverage] = React.useState(0);
    const [median, setMedian] = React.useState(0);
    const [lastDecile, setLastDecile] = React.useState(0);

    const [timeDelayData, setTimeDelayData] = React.useState<IJobTimeStats[]>([]);

    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        setAverage(0);
        setMedian(0);
        setLastDecile(0);
        setTimeDelayData([]);
        setIsLoading(false);
    }, [startDate, endDate, ultimateSiteId]);

    const handleStartDateChange = (date: any) => {
        setStartDate(date);
        if (endDate.getTime() < (date as Date).getTime()) {
            setEndDate(date);
        }
    };

    const handleEndDateChange = (date: any) => {
        setEndDate(date);
    };

    const handleUltimateSiteIDChange = (siteId: any) => {
        setUltimateSiteId(siteId.target.value);
    };

    function getJobQueryFilter(startDate: Date, endDate: Date, ultimateSite: string) {
        return `executionStartTime=${dateToISOWithReducedPrecision(startDate)}` +
            `&executionEndTime=${dateToISOWithReducedPrecision(endDate)}` +
            `&ultimateSite=${ultimateSite}`;
    }

    function getUrl(startDate: Date, endDate: Date, ultimateSite: string) {
        return window.location.origin + '/api/v2/jobs/report?' + getJobQueryFilter(startDate, endDate, ultimateSite);
    }

    function notifyError(message: string) {
        console.error(message);
        enqueueSnackbar(message, { variant: "error" });
    }

    function submit() {
        submitAsync()
            .catch(error => {
                console.error(error);
            });
    }

    async function submitAsync() {
        enqueueSnackbar("Calculating delays", { variant: "info" });
        setIsLoading(true);
        try {
            let jobs = await fetchApi.run(getUrl(startDate, endDate, ultimateSiteId));

            if (!jobs)
                throw Error("Unknown error");

            enqueueSnackbar("Delays successfully retrieved !", { variant: "success" });

            calculateData(jobs);

            return jobs;
        }

        catch (error) {
            let errorMessage = "Bill Reporting failed ";
            if (error instanceof Error) {
                errorMessage += error.message;
            }
            notifyError(errorMessage);
        }
    }

    function calculateData(jobs: IJob[]) {
        let tmpTimeDelayData: IJobTimeStats[] = [];
        let countStartedJobs = 0;

        let timeForAvg = 0;
        let hasMedianBeenSet = false;
        let hasLastDecileBeenSet = false;
        
        for (var i = 0; i < 20; i++) {
            tmpTimeDelayData.push({
                delay: i + 1,
                count: 0,
                percentage: "0"
            });
        }

        jobs.forEach((job: IJob) => {
            if (job.executionInformation?.startTime !== undefined) {
                let startToEndTimeInSec = 10;
                if (job.executionInformation.endTime !== undefined) {
                    startToEndTimeInSec = ((new Date(job.executionInformation.endTime)).getTime() - (new Date(job.executionInformation.startTime)).getTime()) / 1000;
                }
                if (!(job.executionInformation.outcome === JobOutcome.Cancelled && startToEndTimeInSec <= 5)) {

                    let delayStartTimeInMinutes = (new Date(job.executionInformation.startTime).getTime() - new Date(job.creationTime).getTime()) / (1000 * 60);
                    let roundedUpMinute = Math.ceil(delayStartTimeInMinutes);

                    if (roundedUpMinute === 0) {
                        tmpTimeDelayData[roundedUpMinute].count++;
                    }
                    else if (roundedUpMinute > 0 && roundedUpMinute < 20) {
                        tmpTimeDelayData[roundedUpMinute - 1].count++;
                    }
                    else if (roundedUpMinute >= 20) {
                        tmpTimeDelayData[19].count++;
                    }
                    else {
                        console.log(job);
                    }
                    countStartedJobs++;
                    timeForAvg += delayStartTimeInMinutes;
                }
            }
        });

        if (countStartedJobs > 0)
            setAverage(Math.ceil(timeForAvg / countStartedJobs));

        let sumPercentage = 0;
        tmpTimeDelayData.forEach((jobTimeStats: IJobTimeStats) => {
            sumPercentage += jobTimeStats.count * 100 / countStartedJobs;
            jobTimeStats.percentage = sumPercentage.toFixed(2);
            if (sumPercentage >= 50 && !hasMedianBeenSet) {
                setMedian(jobTimeStats.delay);
                hasMedianBeenSet = true;
            }
            if (sumPercentage >= 90 && !hasLastDecileBeenSet) {
                setLastDecile(jobTimeStats.delay);
                hasLastDecileBeenSet = true;
            }
        });

        setTimeDelayData(tmpTimeDelayData);
    }

    function DisplayResult() {
        console.log(timeDelayData);
        if (timeDelayData.length > 0) {
            return (
                <div>
                    <StyledPaper>
                        <Title>Start Time Delay</Title>
                        <Grid container direction="row" style={{ width: '100%' }}>
                            <Grid item xs>
                                <Box component="div" display="block" style={{ fontSize: 20, margin: "10px" }}>Average: {average} minutes</Box>
                            </Grid>
                            <Grid item xs>
                                <Box component="div" display="block" style={{ fontSize: 20, margin: "10px" }}>Median: {median} minutes</Box>
                            </Grid>
                            <Grid item xs>
                                <Box component="div" display="block" style={{ fontSize: 20, margin: "10px" }}>Last Decile: {lastDecile} minutes</Box>
                            </Grid>
                        </Grid>
                        <ResponsiveContainer width="100%" height="80%">
                            <ComposedChart data={timeDelayData} margin={{ top: 30, right: 10, left: 0, bottom: 10 }}>
                                <XAxis dataKey="delay"/>
                                <YAxis yAxisId="left">
                                    <Label value="Number of Jobs" angle={-90} position="insideLeft" />
                                </YAxis>
                                <YAxis yAxisId="right" orientation="right">
                                    <Label value="Percentage" angle={90} position="insideRight" />
                                </YAxis>
                                <Tooltip />
                                <Legend />
                                <CartesianGrid stroke="#f5f5f5" />
                                <Bar yAxisId="left" dataKey="count" name="Jobs" barSize={20} fill="#413ea0" />
                                <Line yAxisId="right" type="monotone" dataKey="percentage" name="Percentage" stroke="#ff7300" />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </StyledPaper>
                </div>
            );
        }
        else if (isLoading) {
            return (<CenteredProgress/>);
        }
        else {
            return (<div></div>);
        }
    }

    return (
        <div>
            <StyledForm noValidate autoComplete="off">
                <Grid container spacing={1} mt={0.5} mx={0} style={{ width: '100%' }}>
                    <Grid item xs={2}>
                        <DatePicker
                            label="Start Date"
                            value = {startDate}
                            onChange={handleStartDateChange}
                            format="MM/dd/yyyy"
                            slotProps={{
                                textField: {
                                variant: "standard",
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DatePicker
                            label="End Date"
                            value = {endDate}
                            onChange={handleEndDateChange}
                            format="MM/dd/yyyy"
                            slotProps={{
                                textField: {
                                variant: "standard",
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{ width: '100%' }}>
                    <Grid item xs={4}>
                        <StyledTextField
                            type="text"
                            id="ultimate-site-id"
                            label="Ultimate Site ID"
                            style={{ width: '100%' }}
                            margin="normal"
                            onChange={handleUltimateSiteIDChange}
                            InputProps={{ required: false }}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={3} mt={1}>
                        <StyledButton variant="contained" color="secondary"  onClick={submit} >
                            Calculate
                        </StyledButton>
                    </Grid>
                </Grid>
            </StyledForm>
            <DisplayResult/>
        </div>
    );
}
